import React from "react"
import { makeStyles, Container, Typography } from "@material-ui/core"

import Youtube from "./assets/youtube.svg"
import Instagram from "./assets/instagram.svg"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

function Slide({ slide }) {
  const classes = useStyle()

  function renderIcon() {
    if (slide.icon === "Youtube") {
      return <Youtube />
    }
    if (slide.icon === "Instagram") {
      return <Instagram />
    }
    return null
  }

  return (
    <a
      target="_blank"
      href={slide.href}
      rel="noreferrer"
      className={classes.slide}
    >
      <ImageWithLazyload
        src={slide.backgroundImage}
        className={classes.picture}
      />
      <Typography color="secondary" className={classes.text}>
        {slide.text}
      </Typography>
      <div className={classes.icon}>{renderIcon()}</div>
    </a>
  )
}

export default function VideoSlider({ videoSlides, title }) {
  const classes = useStyle()
  return (
    <div>
      <Container maxWidth="md">
        <Typography variant="h2" component="h3" color="secondary">
          {title}
        </Typography>
      </Container>
      <div className={classes.block}>
        <Container maxWidth="md" className={classes.container}>
          <div className={classes.inner}>
            {videoSlides.map(slide => (
              <Slide slide={slide} />
            ))}
          </div>
        </Container>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  block: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      marginLeft: 500,
      marginRight: 500,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar-track": {
        marginLeft: 200,
        marginRight: 200,
      },
    },
    "&:hover": {
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f6f6f6",
        border: "1px solid #f6f6f6",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  picture: {
    height: 400,
    width: 400,
    minHeight: 400,
    minWidth: 400,
    objectFit: "cover",
    [theme.breakpoints.down("xs")]: {
      width: 280,
      height: 280,
      minHeight: 280,
      minWidth: 280,
    },
  },
  icon: {
    position: "absolute",
    right: 30,
    top: 340,
    width: 40,
    height: 40,
    [theme.breakpoints.down("xs")]: {
      top: 230,
      right: 20,
      width: 30,
      height: 30,
    },
  },
  inner: {
    display: "flex",
    position: "absolute",
    left: theme.spacing(2),
    top: 40,
  },
  container: {
    position: "relative",
    height: 570,
    [theme.breakpoints.down("xs")]: {
      height: 400,
    },
  },
  text: {
    color: ({ color }) => color,
    zIndex: 2,
    maxWidth: "90%",
    marginTop: theme.spacing(2),
  },
  slide: {
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "flex-start",
    marginRight: 40,
    [theme.breakpoints.down("xs")]: {
      width: 280,
      height: 280,
      marginRight: theme.spacing(2),
    },
    "&:hover $text": {
      textDecoration: "underline",
    },
  },
}))
