import React from "react"
import showdown from "showdown"
import { makeStyles } from "@material-ui/core"

const converter = new showdown.Converter()

export default function BlueBanner({ content, picto }) {
  const classes = useStyles()
  return (
    <div className={classes.container}>
      {picto && <img width={35} src={picto.publicURL} alt={"header-picto"} />}
      <span dangerouslySetInnerHTML={{ __html: converter.makeHtml(content) }} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    height: theme.spacing(8),
    color: "white",
    display: "flex",
    position: "sticky",
    top: 0,
    zIndex: 1000,
    backgroundColor: theme.palette.text.turquoise,
    alignItems: "center",
    justifyContent: "center",
    "& p": {
      margin: 0,
      padding: theme.spacing(1),
    },
    "& a": {
      color: "white",
    },
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
}))
