import React, { useState, useEffect } from "react"
import {
  makeStyles,
  Typography,
  Container,
  ButtonBase,
  useMediaQuery,
  useTheme,
} from "@material-ui/core"
import Lottie from "react-lottie"

import RightArrow from "./assets/rightArrow.svg"
import LeftArrow from "./assets/leftArrow.svg"
import animatedCircle from "./assets/animatedCircle.json"
import { RoughNotation } from "react-rough-notation"

const circleAnimationOptions = {
  loop: false,
  animationData: animatedCircle,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export default function Reinsurance({
  firstTextBefore,
  firstTextAfter,
  firstTextAnimated,
  firstTextAnimation,
  secondTextBefore,
  secondTextAfter,
  secondTextAnimated,
  secondTextAnimation,
  thirdTextBefore,
  thirdTextAfter,
  thirdTextAnimated,
  thirdTextAnimation,
  tabTitle,
}) {
  const [slide, setSlide] = useState(1)
  const [animate1, setAnimate1] = useState(false)
  const [animate2, setAnimate2] = useState(false)
  const [animate3, setAnimate3] = useState(false)
  const [animatedSlide, setAnimatedSlide] = useState(null)
  const resetAnimations = () => {
    setAnimate1(false)
    setAnimate2(false)
    setAnimate3(false)
  }
  useEffect(() => {
    setTimeout(() => setAnimatedSlide(slide), 500)
  }, [slide])
  useEffect(() => {
    resetAnimations()
    setTimeout(() => setAnimate1(true), 1000)
    setTimeout(() => setAnimate2(true), 2000)
    setTimeout(() => setAnimate3(true), 3000)
  }, [tabTitle])

  const classes = useStyle({ slide })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  function isAnimated(text, animation) {
    if (
      !text ||
      text === "" ||
      (animation !== "Circle" && animation !== "Underline")
    ) {
      return false
    } else {
      return true
    }
  }
  function renderAnimatedText(text, animation, animate) {
    if (!isAnimated(text, animation)) {
      return
    }
    if (animation === "Circle") {
      return (
        <span className={classes.firstTextContainer}>
          <Typography
            component="span"
            variant="body2"
            className={classes.price}
          >
            {text}
          </Typography>
          <span className={classes.animationContainer}>
            <Lottie
              isStopped={!animate}
              Animated={animate}
              options={{
                ...circleAnimationOptions,
                autoplay: false,
              }}
              height={170}
              width={170}
              isClickToPauseDisabled
            />
          </span>
        </span>
      )
    } else {
      return (
        <RoughNotation
          type="underline"
          color="#A4E7DB"
          show={animate}
          animationDuration={400}
          className={classes.line}
        >
          <Typography
            component="span"
            variant="body2"
            className={classes.price}
          >
            {text}
          </Typography>
        </RoughNotation>
      )
    }
  }
  return (
    <Container maxWidth="md" className={classes.container}>
      <div className={classes.inner}>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <ButtonBase
            disableRipple
            disabled={slide === 2}
            onClick={() => setSlide(prev => prev + 1)}
            className={classes.rightArrow}
          >
            <RightArrow />
          </ButtonBase>
          <ButtonBase
            onClick={() => setSlide(prev => prev - 1)}
            className={classes.leftArrow}
            disabled={slide === 0}
            disableRipple
          >
            <LeftArrow />
          </ButtonBase>
          <div className={classes.inner2}>
            <div className={classes.text}>
              <Typography
                component={
                  isAnimated(firstTextAnimated, firstTextAnimation) &&
                  (!firstTextAfter || firstTextAfter === "")
                    ? "div"
                    : "span"
                }
                className={classes.before}
              >
                {firstTextBefore}
              </Typography>
              {renderAnimatedText(
                firstTextAnimated,
                firstTextAnimation,
                (animate1 && !isMobile) || (isMobile && animatedSlide === 0)
              )}
              <Typography component="span" className={classes.after}>
                {firstTextAfter}
              </Typography>
            </div>
            <div className={classes.divider} />
            <div className={classes.text}>
              <Typography
                component={
                  isAnimated(secondTextAnimated, secondTextAnimation) &&
                  (!secondTextAfter || secondTextAfter === "")
                    ? "div"
                    : "span"
                }
                className={classes.before}
              >
                {secondTextBefore}
              </Typography>
              {renderAnimatedText(
                secondTextAnimated,
                secondTextAnimation,
                (animate2 && !isMobile) || (isMobile && animatedSlide === 1)
              )}
              <Typography component="span" className={classes.after}>
                {secondTextAfter}
              </Typography>
            </div>
            <div className={classes.divider} />
            <div className={classes.text}>
              <Typography
                component={
                  isAnimated(thirdTextAnimated, thirdTextAnimation) &&
                  (!thirdTextAfter || thirdTextAfter === "")
                    ? "div"
                    : "span"
                }
                className={classes.before}
              >
                {thirdTextBefore}
              </Typography>
              {renderAnimatedText(
                thirdTextAnimated,
                thirdTextAnimation,
                (animate3 && !isMobile) || (isMobile && animatedSlide === 2)
              )}
              <Typography component="span" className={classes.after}>
                {thirdTextAfter}
              </Typography>
            </div>
          </div>
        </div>
      </div>
    </Container>
  )
}

const useStyle = makeStyles(theme => ({
  before: {
    marginRight: 7,
    fontSize: "21px",
  },
  after: {
    marginLeft: 7,
    fontSize: "21px",
  },
  firstTextContainer: {
    position: "relative",
  },
  animationContainer: {
    position: "absolute",
    left: -67,
    top: -72,
  },
  rightArrow: {
    position: "absolute",
    right: theme.spacing(2),
    zIndex: 2,
    top: "40%",
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "unset",
    },
  },
  leftArrow: {
    position: "absolute",
    left: theme.spacing(2),
    top: "40%",
    zIndex: 2,
    display: "none",
    [theme.breakpoints.down("xs")]: {
      display: "unset",
    },
  },
  container: {
    position: "relative",
    height: 100,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 100,
      overflow: "hidden",
      height: 85,
    },
  },
  inner: {
    position: "absolute",
    top: "-50%",
    left: theme.spacing(2),
    bottom: "50%",
    backgroundColor: theme.palette.secondary.main,
    right: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      top: 0,
      bottom: 0,
    },
  },
  inner2: {
    position: "absolute",
    left: 0,
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    padding: theme.spacing(3),
    // paddingBottom: theme.spacing(3),
    right: 0,
    top: 0,
    bottom: 0,
    [theme.breakpoints.down("xs")]: {
      paddingLeft: 0,
      paddingRight: 0,
      right: "unset",
      transform: ({ slide }) =>
        `translateX(calc(-${slide * 100}vw + ${slide * theme.spacing(4)}px))`,
      transition: "all 0.3s ease",
    },
  },
  text: {
    width: "28%",
    maxWidth: 220,
    color: "white",
    lineHeight: "27px",
    textAlign: "center",
    // paddingLeft: theme.spacing(9),
    // paddingRight: theme.spacing(9),
    // [theme.breakpoints.down("md")]: {
    //   paddingLeft: theme.spacing(3),
    //   paddingRight: theme.spacing(3),
    // },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
      width: `calc(100vw - ${theme.spacing(4)}px)`,
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
    },
  },
  line: {
    fill: "#A4E7DB",
  },
  price: {
    fontSize: "19px",
  },
  divider: {
    height: "100%",
    width: 1,
    backgroundColor: "#A4E7DB",
    [theme.breakpoints.down("xs")]: {
      width: 0,
    },
  },
  textMiddle: {
    width: "33%",
    color: "white",
    textAlign: "center",
    paddingLeft: theme.spacing(9),
    paddingRight: theme.spacing(9),
    borderLeft: "1px solid #A4E7DB",
    borderRight: "1px solid #A4E7DB",
    [theme.breakpoints.down("md")]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      border: "none",
      width: `calc(100vw - ${theme.spacing(4)}px)`,
      paddingLeft: theme.spacing(9),
      paddingRight: theme.spacing(9),
    },
  },
}))
