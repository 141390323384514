import React from "react"
import { makeStyles, Typography, Link, Container } from "@material-ui/core"

import EcoIcon from "@material-ui/icons/Eco"
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"

export default function LandingPressBlock({ pressArticles, isPreview }) {
  const styles = useStyles()
  return (
    <Container maxWidth="md" className={styles.pressBlockContainer}>
      <Typography variant="h2" className={styles.subtitle}>
        Ils parlent de nous
      </Typography>
      <div className={styles.pressBlockContent}>
        <div className={styles.pressArticlesWrapper}>
          {pressArticles.map((pressArticle, index) => (
            <div className={styles.pressItem} key={index}>
              <p>
                <Link
                  href={pressArticle.pressLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ImgWithLazyload
                    isPreview={isPreview}
                    src={pressArticle.pressPicture}
                    alt={pressArticle.pressAltPicture}
                  />
                  <br />
                  <span className={styles.span}>{pressArticle.siteName}</span>
                </Link>
              </p>
            </div>
          ))}
        </div>
      </div>
      <span className={styles.pressBlockLeaf}>
        <EcoIcon fontSize="large" style={{ color: "white" }} />
      </span>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  pressBlockContainer: {
    padding: "64px 0 96px",
    margin: "0 auto",
    position: "relative",
    background: theme.palette.yellow,
    [theme.breakpoints.down("xs")]: {
      padding: "64px 0 92px",
    },
    "&::after": {
      display: "block",
      content: "",
      clear: "both",
    },
  },

  pressBlockContent: {
    padding: "0 1em",
    overflow: "auto",
    "&::after": {
      display: "block",
      content: "",
      clear: "both",
    },
  },

  subtitle: {
    textAlign: "center",

    // override browser defaults
    marginBlockStart: 0,
    marginBlockEnd: 0,
    paddingBottom: 48,

    [theme.breakpoints.down("xs")]: {
      paddingBottom: 40,
    },
  },

  pressArticlesWrapper: {
    display: "grid",
    gridTemplateColumns: "repeat(5, 1fr)",
    gridGap: 32,

    [theme.breakpoints.down("xs")]: {
      gridGap: theme.spacing(),
    },
  },
  pressBlockLeaf: {
    position: "absolute",
    bottom: -38,
    borderRadius: "50%",
    height: 78,
    width: 78,
    "& svg": {
      width: 35,
      margin: "calc(78px / 4) auto auto",
      display: "flex",
    },
    background: theme.palette.secondary.main,
    left: "calc(50% - 39px)",
  },

  pressItem: {
    float: "left",
    minHeight: 170,
    position: "relative",
    color: "white",
    display: "flex",
    [theme.breakpoints.down("xs")]: {
      alignItems: "center",
      background: "rgba(255, 255, 255, 0.2)",
      width: 120,
      borderRadius: 10,
      "&::after": {
        display: "block",
        content: "",
        clear: "both",
      },
    },

    "& img": {
      /* Prevent colored pictures to be rendered with colors */
      /*filter: brightness(0) invert(1);*/
      objectFit: "cover",
      width: "80%",
      margin: "0 10%",
      position: "relative",
      top: "35%",
      transform: "translateY(-50%)",
      justifyContent: "center",
      alignItems: "center",
      [theme.breakpoints.down("xs")]: {
        maxWidth: 140,
        transform: "translateY(0)",
      },
    },
    "& p": {
      margin: "0 auto",
      marginBottom: 48,
    },
    "& span": {
      position: "absolute",
      bottom: 16,
      color: "white",
      left: 0,
      width: "100%",
      textAlign: "center",
      display: "inline-block",
      fontWeight: 700,
    },
  },
}))
