import React from "react"
import { Container, makeStyles, Typography } from "@material-ui/core"
import Arrow from "./assets/arrow.svg"
import Ticks from "./assets/ticks.svg"
import useColor from "src/hooks/useColor"

function Number({ value, separator }) {
  const classes = useNumberStyles({ separator })

  return (
    <div className={classes.number}>
      <Typography color="secondary" className={classes.numberValue}>
        {value}
      </Typography>
    </div>
  )
}

const useNumberStyles = makeStyles(theme => ({
  number: {
    width: 76,
    height: 100,
    backgroundColor: "#FFEA9C",
    borderRadius: 10,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginRight: ({ separator }) =>
      separator ? theme.spacing(3) : theme.spacing(),
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      height: 57,
      borderRadius: 5,
      marginRight: ({ separator }) => (separator ? theme.spacing() : 4),
    },
  },
  numberValue: {
    fontSize: 31,
    fontWeight: 700,
    textAlign: "center",
    [theme.breakpoints.down("xs")]: {
      fontSize: 17,
    },
  },
}))

export default function WasteCount({
  title,
  text,
  backgroundColor,
  secondBackgroundColor,
}) {
  const topColor = useColor(backgroundColor)
  const bottomColor = useColor(secondBackgroundColor)
  const classes = useStyles({ topColor, bottomColor })
  return (
    <div className={classes.container}>
      <Container maxWidth="md" className={classes.inner}>
        <div className={classes.numbers}>
          <Number value={2} separator />
          <Number value={1} />
          <Number value={2} />
          <Number value={2} separator />
          <Number value={0} />
          <Number value={2} />
          <Number value={9} />
        </div>
        <div className={classes.textContainer}>
          <Typography color="secondary" className={classes.title}>
            {title}
            <Ticks className={classes.ticks} />
          </Typography>
          <Typography variant="body2" color="secondary">
            {text}
          </Typography>
        </div>
        <Arrow className={classes.arrow} />
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  title: {
    fontWeight: 700,
    marginBottom: theme.spacing(2),
    fontSize: 19,
    position: "relative",
    marginRight: "auto",
  },
  ticks: {
    position: "absolute",
    top: -17,
    right: -20,
  },
  container: {
    background: ({ topColor, bottomColor }) =>
      `linear-gradient(${
        topColor === "inherit" ? "transparent" : topColor
      } 50%, ${bottomColor === "inherit" ? "transparent" : bottomColor} 50%)`,
    marginBottom: -2,
    [theme.breakpoints.down("sm")]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  numbers: {
    zIndex: 2,
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  textContainer: {
    zIndex: 2,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),

      marginTop: theme.spacing(4),
    },
  },
  arrow: {
    position: "absolute",
    bottom: 0,
    right: theme.spacing(10),
    width: 480,
    [theme.breakpoints.down("sm")]: {
      left: 0,
      right: "unset",
    },
    [theme.breakpoints.down("xs")]: {
      width: "90%",
    },
  },
  inner: {
    position: "relative",
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    backgroundColor: theme.palette.yellow,
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: theme.spacing(3),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(8),
    },
  },
}))
