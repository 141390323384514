import * as React from "react"
import {
  MenuItem,
  Grid,
  Container,
  makeStyles,
  Button,
  Box,
  Typography,
  ButtonBase,
  Hidden,
} from "@material-ui/core"
import { RoundedSelect } from "components/roundedSelect/RoundedSelect"
import { RoundedInput } from "components/roundedInput/RoundedInput"
import PlaceIcon from "@material-ui/icons/Place"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"
import { repairStorage } from "../../helpers/repairStorage"
import IconGuarantee from "./assets/iconGuarantee.svg"
import IconLater from "./assets/iconLater.svg"
import useColor from "src/hooks/useColor"
import MobileSearchForm from "../mobileSearchForm"
import SearchIcon from "@material-ui/icons/Search"

export function RepairSearch({
  products,
  searchTitle,
  backgroundColor,
  quickBookingBackgroundImage,
  title,
  variant,
  subtitle,
}) {
  const color = useColor(backgroundColor || "Primary")
  const textColor = backgroundColor === "Secondary" ? "primary" : "secondary"
  const classes = useStyle({
    variant,
    color,
  })
  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef(null)
  const scrollListener = React.useCallback(() => {
    if (ref.current && ref.current.getBoundingClientRect().top < -20) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [ref])

  React.useEffect(() => {
    repairStorage.remove()
  }, [])

  React.useEffect(() => {
    if (ref.current) {
      document.addEventListener("scroll", scrollListener)
    }
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [ref, scrollListener])

  const handleSelect = React.useCallback(
    ({ target: { value } }) => {
      let product = {}
      if (value === "multi_product") {
        product["name"] = "multi_product"
      } else {
        product = products.find(product => product._id === value)
      }

      if (!product) {
        return
      }
      repairStorage.setProduct(product)
    },
    [products]
  )

  const handleChangeAddress = React.useCallback(address => {
    repairStorage.setAddress(address)
    if (typeof window !== "undefined") {
      window.location.href = "/rendez-vous-reparateur"
    }
  }, [])

  const splitTitle = title?.split(/<|>/).filter(i => !i.includes("span"))
  return (
    <div className={classes.backgroundContainer}>
      <div className={classes.background}>
        <Hidden xsDown implementation="css">
          <ImageWithLazyload
            src={quickBookingBackgroundImage}
            className={classes.img}
          />
        </Hidden>
      </div>
      <div>
        <div className={classes.container}>
          <div className={classes.svgContainer}>
            <svg
              className={classes.svg}
              version="1.1"
              id="background-shape"
              x="0px"
              y="0px"
              viewBox="0 0 1014.9 657"
              height="100%"
              preserveAspectRatio="xMinYMin slice"
              fill="currentColor"
            >
              <path d="M910,279A729.39,729.39,0,0,1,965,0H0V657H1014.9A729.3,729.3,0,0,1,910,279Z" />
            </svg>
          </div>
          {variant && (
            <Box display="flex" alignItems="center">
              <Container maxWidth="md">
                <div maxWidth="md" style={{ position: "relative" }}>
                  <Typography
                    component="h1"
                    className={classes.titleTextVariant}
                    color={textColor}
                  >
                    {splitTitle[0]}
                    {splitTitle.length > 0 && (
                      <span style={{ color: "white" }}>{splitTitle[1]}</span>
                    )}
                    {splitTitle.length > 1 && splitTitle[2]}
                  </Typography>
                  <Typography color={textColor} component="h2">
                    {subtitle}
                  </Typography>
                </div>
              </Container>
            </Box>
          )}
          <div
            className={
              variant ? classes.formContainer : classes.formContainerVariant
            }
          >
            <div ref={ref}>
              <Hidden xsDown implementation="css">
                <div className={isSticky ? classes.isSticky : undefined}>
                  <div className={classes.desktopContainer}>
                    <Grid container className={classes.whiteBackground}>
                      <Grid item>
                        <Box pr={1}>
                          <RoundedSelect
                            fullWidth
                            placeholder="Appareil à réparer"
                            onChange={handleSelect}
                            displayMultiProduct
                          >
                            {products &&
                              products.map(product => (
                                <MenuItem key={product._id} value={product._id}>
                                  {product.name}
                                </MenuItem>
                              ))}
                          </RoundedSelect>
                        </Box>
                      </Grid>
                      <Grid item className={classes.flex}>
                        <Box pr={1} pl={1}>
                          <GoogleAutoComplete
                            onChange={handleChangeAddress}
                            id="google-automplete-mobile"
                          >
                            <RoundedInput
                              fullWidth
                              height={42}
                              placeholder="Saisissez votre adresse"
                              InputProps={{
                                endAdornment: (
                                  <PlaceIcon style={{ color: "#A4E7DB" }} />
                                ),
                              }}
                            />
                          </GoogleAutoComplete>
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box pl={1}>
                          <Button
                            href="/rendez-vous-reparateur"
                            variant="contained"
                            color={textColor}
                          >
                            {searchTitle || "Prendre rendez-vous"}
                          </Button>
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Hidden>
            </div>
            <Hidden xsDown implementation="css">
              {variant && (
                <Box mb={19} ml={7} mt={2} mr={1} position="relative">
                  <Grid container className={classes.iconContainer}>
                    <Grid item xs={4} className={classes.iconVariant}>
                      <IconGuarantee />{" "}
                      <Typography>Réparation garantie 6 mois</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.iconVariant}>
                      <IconLater /> <Typography>Déplacements inclus</Typography>
                    </Grid>
                    <Grid item xs={4} className={classes.iconVariant}>
                      <IconGuarantee />{" "}
                      <Typography>Pack zéro risque</Typography>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Hidden>
            <Hidden smUp implementation="css">
              <Box pt={3} px={2}>
                <MobileSearchForm products={products}>
                  <Box className={classes.mobileProduct}>
                    <ButtonBase className={classes.productButton}>
                      <Typography>Prendre rendez-vous</Typography>
                      <span className={classes.productIconContainer}>
                        <SearchIcon className={classes.searchIcon} />
                      </span>
                    </ButtonBase>
                  </Box>
                </MobileSearchForm>
              </Box>
            </Hidden>
          </div>
          {!variant && (
            <Box
              mt={{ xs: 6, md: 2 }}
              mb={3}
              display="flex"
              alignItems="center"
            >
              <Container maxWidth="md">
                <div
                  maxWidth="md"
                  className={classes.title}
                  style={{ position: "relative" }}
                >
                  <Typography
                    variant="h1"
                    className={classes.titleText}
                    color={textColor}
                  >
                    <div dangerouslySetInnerHTML={{ __html: title }} />
                  </Typography>
                  <Typography color={textColor} component="h2">
                    {subtitle}
                  </Typography>
                </div>
              </Container>
            </Box>
          )}
          <Hidden smUp implementation="css">
            <div className={classes.mobileImageContainer}>
              <ImageWithLazyload
                src={quickBookingBackgroundImage}
                className={classes.img}
              />
            </div>
          </Hidden>
        </div>
      </div>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  backgroundContainer: {
    position: "relative",
    overflow: "hidden",
  },
  background: {
    position: "absolute",
    top: 0,
    right: 0,
    width: 650,
  },
  container: ({ color, variant }) => ({
    height: "auto",
    overflow: "hidden",
    position: "relative",
    paddingTop: variant ? 120 : 80,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 80,
      backgroundColor: color,
    },
  }),
  titleText: {
    fontSize: 55,
    fontWeight: 700,
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      marginBottom: theme.spacing(2),
    },
  },
  titleTextVariant: {
    fontSize: 36,
    fontWeight: 700,
    maxWidth: 700,
    "@media(max-width: 1000px)": {
      maxWidth: 500,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 32,
      maxWidth: "unset",
      marginBottom: theme.spacing(2),
    },
  },
  svgContainer: ({ color }) => ({
    display: "none",
    color,
    height: "100%",
    backgroundColor: color,
    position: "absolute",
    top: 0,
    left: 0,
    width: "calc(100vw - 635px)",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  }),
  iconContainer: {
    display: "flex",
    justifyContent: "flex-start",
    marginLeft: "max(calc(50% - 604px) , 24px)",
  },
  iconVariant: {
    display: "flex",
    alignItems: "center",
    flexBasis: "auto",
    marginRight: theme.spacing(3),
    "& > p": {
      marginLeft: theme.spacing(1),
      fontSize: 15,
    },
  },
  svg: {
    position: "absolute",
    right: -130,
    height: "100%",
  },
  title: {
    lineHeight: "110%",
    marginTop: 70,
    width: "40%",
    marginRight: "auto",
    position: "relative",
    marginBottom: 150,
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      width: "100%",
      marginTop: 0,
    },
  },
  isSticky: {
    position: "fixed !important",
    margin: "auto",
    zIndex: 20,
    borderRadius: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    left: 0,
    top: 0,
    "& > div": {
      margin: "auto !important",
      marginTop: "0 !important",
      marginBottom: "0 !important",
    },
  },
  whiteBackground: {
    borderRadius: 50,
    padding: theme.spacing(2),
    backgroundColor: theme.palette.common.white,
  },
  desktopContainer: ({ variant }) => ({
    position: "relative",
    maxWidth: variant ? 900 : 744,
    marginLeft: variant ? "max(calc(50% - 604px) , 24px)" : "auto",
    marginRight: "auto",
    marginTop: 0,
    "@media(max-width: 1000px)": {
      maxWidth: 600,
    },
  }),
  flex: {
    flex: 1,
  },
  mobileImageContainer: {
    marginTop: theme.spacing(3),
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  img: {
    margin: "auto",
    padding: theme.spacing(2),
    display: "block",
    width: "100%",
  },
  formContainer: {
    height: 270,
    marginTop: theme.spacing(7),
    [theme.breakpoints.down("xs")]: {
      marginTop: 0,
      height: 100,
    },
  },
  formContainerVariant: {
    height: 100,
  },
  searchIcon: {
    color: theme.palette.secondary.main,
  },
  productButton: {
    paddingLeft: theme.spacing(2),
    borderRadius: 50,
    width: "100%",
    display: "flex",
    color: theme.palette.text.primary,
    justifyContent: "space-between",
    "& p": {
      lineHeight: 0,
    },
  },
  productIconContainer: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.green,
    width: 36,
    height: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
    borderRadius: "50%",
    "& > svg": {
      width: 14,
    },
  },
  mobileProduct: {
    width: "100%",
    padding: theme.spacing(1),
    borderRadius: 50,
    backgroundColor: theme.palette.common.white,
    border: "1px #dbdfe8 solid",
    margin: "auto",
  },
}))
