import React from "react"
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"
import { Container, makeStyles, Typography } from "@material-ui/core"

import EmailForm from "src/utils/components/emailForm"

export default function OptInBlock({
  optInImage,
  optInImageAlt,
  optInTitle,
  optInButton,
  optInText,
  optInTextRgpd,
  isPreview,
}) {
  const styles = useStyles()

  return (
    <Container maxWidth="md" className={styles.container}>
      <div className={styles.blockSubcontainer}>
        <div className={styles.shadowContainerImage}>
          <ImgWithLazyload
            isPreview={isPreview}
            className={styles.imageNoBackground}
            alt={optInImageAlt}
            src={optInImage}
          />
        </div>
        <div className={styles.textContainer}>
          <Typography variant="h2" component="h3">
            {optInTitle}
          </Typography>
          <Typography className={styles.text} variant="body2">
            {optInText}
          </Typography>
          <EmailForm submitText={optInButton} optInTextRgpd={optInTextRgpd} />
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  goButton: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  text: {
    marginTop: theme.spacing(),
  },
  checkbox: {
    "& path": {
      fill: "white",
    },
  },
  checkcontainer: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(),
    },
  },
  container: {
    backgroundColor: theme.palette.secondary.main,

    [theme.breakpoints.down("sm")]: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      width: "unset",
    },
  },
  blockContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: "0 24px",
    },
  },
  blockSubcontainer: {
    display: "flex",
    justifyContent: "space-between",
    marginRight: theme.spacing(10),
    marginLeft: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
    },
  },

  shadowContainerImage: {
    display: "flex",
    width: "50%",
    objectFit: "cover",
    paddingTop: theme.spacing(2),

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
      paddingTop: 20,
      order: 1,
    },
  },

  textContainer: {
    paddingBottom: theme.spacing(10),
    width: "50%",
    textAlign: "left",
    paddingTop: theme.spacing(10),
    marginLeft: theme.spacing(3),
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      paddingBottom: theme.spacing(1),
      paddingTop: 40,
      margin: 0,
      "& .textContainerTitle": {
        fontSize: 26,
      },
    },
  },

  imageContainerNoBackground: {
    margin: 0,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 48,
    },
  },

  imageNoBackground: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
  signupScroll: {
    position: "relative",
  },
}))
