import * as React from "react"
import {
  MenuItem,
  makeStyles,
  Divider,
  Box,
  Drawer,
  IconButton,
  Typography,
} from "@material-ui/core"
import clsx from "clsx"
import { RoundedInput } from "components/roundedInput/RoundedInput"

import { GoogleAutoComplete } from "components/googleAutoComplete/GoogleAutoComplete"
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos"
import { repairStorage } from "../../helpers/repairStorage"
import Chip from "../shared/Chip"
import CustomMenuItem from "../shared/CustomMenuItem"

const DRAWER_PRODUCT = "DRAWER_PRODUCT"
const DRAWER_ADDRESS = "DRAWER_ADDRESS"

export function MobileSearchForm({ products, children }) {
  const classes = useStyle()
  const [isSticky, setIsSticky] = React.useState(false)
  const ref = React.useRef(null)
  const [drawer, setDrawer] = React.useState(null)
  const scrollListener = React.useCallback(() => {
    if (ref.current && ref.current.getBoundingClientRect().top < -20) {
      setIsSticky(true)
    } else {
      setIsSticky(false)
    }
  }, [ref])

  React.useEffect(() => {
    repairStorage.remove()
  }, [])

  React.useEffect(() => {
    if (ref.current) {
      document.addEventListener("scroll", scrollListener)
    }
    return () => {
      document.removeEventListener("scroll", scrollListener)
    }
  }, [ref, scrollListener])

  const handleOpenSlider = React.useCallback(() => {
    setDrawer(DRAWER_PRODUCT)
  }, [])

  const handleSelect = React.useCallback(
    ({ target: { value } }) => {
      let product = {}
      if (value === "multi_product") {
        product["name"] = "multi_product"
      } else {
        product = products.find(product => product._id === value)
      }

      if (!product) {
        return
      }
      repairStorage.setProduct(product)
    },
    [products]
  )
  const handleSelectProduct = React.useCallback(
    product => () => {
      handleSelect({ target: { value: product._id } })
      setDrawer(DRAWER_ADDRESS)
    },
    [handleSelect]
  )

  const isAddressFieldValid = address => {
    if (!address || !address.address_components) {
      return false
    }
    return !!address.address_components.find(component =>
      component.types.includes("postal_code")
    )?.long_name
  }

  const handleChangeAddress = React.useCallback(address => {
    if (!isAddressFieldValid(address)) {
      return
    }
    repairStorage.setAddress(address)
    if (typeof window !== "undefined") {
      window.location.href = "/rendez-vous-reparateur"
    }
  }, [])

  const handleReturn = React.useCallback(() => {
    setDrawer(prevState => {
      if (prevState === DRAWER_ADDRESS) {
        return DRAWER_PRODUCT
      }
      if (prevState === DRAWER_PRODUCT) {
        return null
      }
    })
  }, [])

  return (
    <Box
      className={clsx(
        { [classes.isSticky]: isSticky },
        classes.mobileContainer
      )}
    >
      <Box width="100%">
        <div onClick={handleOpenSlider}>{children}</div>
        <Drawer
          anchor="right"
          style={{ zIndex: "999" }}
          PaperProps={{
            className: classes.drawer,
          }}
          open={Boolean(drawer)}
        >
          <div className={classes.drawerContainer}>
            <div className={classes.drawerItem}>
              <IconButton
                size="small"
                className={classes.backArrow}
                onClick={handleReturn}
              >
                <ArrowBackIosIcon className={classes.arrowIcon} />
              </IconButton>
              <Typography color="secondary" variant="body2">
                Prendre rendez-vous
              </Typography>
            </div>
            <div className={classes.inputItem}>
              {drawer === DRAWER_ADDRESS && (
                <GoogleAutoComplete
                  onChange={handleChangeAddress}
                  id="mobile-autoComplete"
                >
                  <RoundedInput
                    fullWidth
                    autoFocus // eslint-disable-line jsx-a11y/no-autofocus
                    flat
                    placeholder="Où devons-nous intervenir ?"
                  />
                </GoogleAutoComplete>
              )}
              {drawer === DRAWER_PRODUCT && (
                <div className={classes.placeholder}>
                  Quel appareil souhaitez-vous réparer ?
                </div>
              )}
            </div>

            <Divider />
            {drawer === DRAWER_PRODUCT && (
              <div className={classes.scrollable}>
                {products.map(product => (
                  <MenuItem
                    key={product.id}
                    onClick={handleSelectProduct(product)}
                    className={classes.menuItem}
                  >
                    {product.name}
                  </MenuItem>
                ))}
                <CustomMenuItem
                  key="multi_product"
                  onClick={handleSelectProduct({
                    _id: "multi_product",
                  })}
                  className={classes.menuItem}
                >
                  Plusieurs Appareils{" "}
                  <Box marginLeft={1}>
                    <Chip label="NOUVEAU" />
                  </Box>
                </CustomMenuItem>
              </div>
            )}
          </div>
        </Drawer>
      </Box>
    </Box>
  )
}

const useStyle = makeStyles(theme => ({
  arrowIcon: {
    fontSize: 17,
  },
  isSticky: {
    position: "fixed !important",
    margin: "auto",
    zIndex: 20,
    borderRadius: 0,
    right: 0,
    backgroundColor: theme.palette.common.white,
    left: 0,
    top: 0,
    "& > div": {
      margin: "auto !important",
      marginTop: "0 !important",
      marginBottom: "0 !important",
    },
  },
  mobileContainer: {
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
  },
  productButton: {
    paddingLeft: theme.spacing(2),
    borderRadius: 50,
    width: "100%",
    display: "flex",
    color: theme.palette.text.primary,
    justifyContent: "space-between",
    "& p": {
      lineHeight: 0,
    },
  },
  productIconContainer: {
    marginLeft: theme.spacing(2),
    backgroundColor: theme.palette.green,
    width: 36,
    height: 36,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: theme.palette.common.white,
    borderRadius: "50%",
    "& > svg": {
      width: 14,
    },
  },
  drawer: {
    width: "100%",
    height: "100%",
    left: 0,
  },
  drawerContainer: {
    display: "flex",
    flexDirection: "column",
  },
  inputItem: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(2, 0),
  },
  drawerItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(1, 1),
    poisition: "relative",
    backgroundColor: "#F59BBB",
  },
  backArrow: {
    position: "absolute",
    left: theme.spacing(),
  },
  placeholder: {
    color: theme.palette.secondary.main,
    fontSize: 17,
    fontWeight: 700,
    paddingLeft: theme.spacing(2),
  },
  menuItem: {
    height: 54,
    "&:hover": {
      backgroundColor: "#f5f5f5",
    },
  },
  scrollable: {
    height: "calc(100vh - 79px)",
    overflowY: "auto",
    color: "#3E3E3E",
  },
}))
