import * as React from "react"
import {
  makeStyles,
  Grid,
  Container,
  Typography,
  useTheme,
  useMediaQuery,
} from "@material-ui/core"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

import bg from "!!url-loader!./assets/bg.svg"
import Arrow from "./assets/arrow.svg"

export default function HowItWorksBlock({ workContent, getRef }) {
  const classes = useStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm"))
  const [translateY, setTranslateY] = React.useState("0px")
  const [scrollPercentage, setScrollPercentage] = React.useState(0)
  const refContainer = React.useRef(null)
  const parentRef = React.useRef(null)
  React.useEffect(() => {
    const ref = getRef()
    if (ref) {
      parentRef.current = ref
    }
  }, [getRef])

  const eventListener = React.useCallback(() => {
    const visibleHeight = window.innerHeight
    const offsetTop = parentRef.current.offsetTop - 80
    const end = parentRef.current.clientHeight - visibleHeight + 80
    const scrollInPercent = Math.min(
      100,
      Math.max(0, ((window.scrollY - offsetTop) * 100) / end)
    )
    setScrollPercentage(scrollInPercent)
    setTranslateY(
      `${(refContainer.current.clientHeight - 150) * (scrollInPercent / 100)}px`
    )
  }, [refContainer, parentRef])

  React.useEffect(() => {
    if (refContainer.current) {
      window.addEventListener("scroll", eventListener)
    }

    return () => {
      window.removeEventListener("scroll", eventListener)
    }
  }, [refContainer, eventListener])

  if (!workContent) {
    return null
  }
  return (
    <div className={classes.container}>
      <Container maxWidth="md">
        <Grid container>
          <Grid
            item
            xs={12}
            sm={6}
            className={classes.sticky}
            ref={refContainer}
          >
            {workContent.map(({ text, title, link, url }, index, source) => {
              const isActive =
                scrollPercentage >= (index / 3) * 100 &&
                scrollPercentage <= ((index + 1) / 3) * 100

              return (
                <Grid
                  item
                  xs={12}
                  key={index}
                  style={{
                    paddingBottom: index + 1 !== source.length ? 40 : 0,
                    opacity: !isDesktop ? "1" : isActive ? "1" : "0.5",
                  }}
                >
                  <Typography
                    variant="h2"
                    component="h3"
                    className={classes.title}
                  >
                    {title}
                  </Typography>
                  <Typography variant="body1" className={classes.text}>
                    {text}
                  </Typography>
                  <a href={url} className={classes.url}>
                    {link} <Arrow />
                  </a>
                </Grid>
              )
            })}
            {isDesktop && (
              <span className={classes.nav}>
                <span
                  className={classes.navProgress}
                  style={{ transform: `translateY(${translateY})` }}
                />
              </span>
            )}
          </Grid>
          {isDesktop && (
            <Grid item container xs={6} className={classes.rightContainer}>
              {workContent.map(({ image, text }, index) => (
                <Grid item xs={12} key={index} className={classes.rightItem}>
                  <ImageWithLazyload
                    src={image}
                    alt={text}
                    className={classes.rightImage}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Container>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    position: "relative",
    padding: theme.spacing(15, 0, 15, 0),
  },
  rightContainer: {
    "& > div:not(:last-child)": {
      marginBottom: theme.spacing(50),
    },
    paddingLeft: theme.spacing(8),
  },
  rightItem: {
    height: 500,
    overflow: "hidden",
    position: "relative",
    "&:after": {
      content: "''",
      position: "absolute",
      top: 24,
      left: 30,
      right: 0,
      bottom: 0,
      backgroundImage: `url(${bg})`,
      backgroundRepeat: "repeat",
    },
  },
  rightImage: {
    width: "90%",
    zIndex: 1,
  },
  sticky: {
    position: "sticky",
    alignSelf: "flex-start",
    paddingRight: theme.spacing(0),
    top: 180,
    [theme.breakpoints.up("sm")]: {
      paddingRight: theme.spacing(8),
    },
  },
  title: {
    color: theme.palette.common.white,
    fontSize: 34,
    paddingBottom: theme.spacing(2),
    fontWeight: 500,
  },
  text: {
    color: theme.palette.common.white,
    fontSize: 16,
    lineHeight: "130%",
    paddingBottom: theme.spacing(2),
  },
  nav: {
    position: "absolute",
    left: -22,
    height: 150,
    width: 2,
    top: 0,
  },
  navProgress: {
    backgroundColor: theme.palette.common.white,
    width: "100%",
    height: "100%",
    display: "block",
  },
  url: {
    color: "#FFD747",
    display: "flex",
    alignItems: "center",
    "& > svg": {
      paddingLeft: theme.spacing(1),
    },
  },
}))
