import React, { Component } from "react"
import IconButton from "@material-ui/core/IconButton"
import Dialog from "@material-ui/core/Dialog"
import { withStyles } from "@material-ui/core/styles"
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined"
import showdown from "showdown"
const converter = new showdown.Converter()

const style = {
  root: {
    padding: "32px 72px 72px",
    textAlign: "center",
  },
  closeButton: {
    display: "none",
  },
  title: {
    fontFamily: "FaroWeb-BoldLucky",
    fontWeight: "800",
    fontSize: "38px",
    lineHeight: "47px",
    color: "#2B3B5C",
  },
  subtitle: {
    fontFamily: "FaroWeb-RegularLucky",
    fontSize: "19px",
    lineHeight: "140%",
    color: "#8795B3",
  },
  textHeader: {
    fontFamily: "FaroWeb-RegularLucky",
    fontWeight: "600",
    fontSize: "15px",
    lineHeight: "140%",
    marginTop: "36px",
    color: "#2B3B5C",
  },
  text: {
    fontFamily: "FaroWeb-RegularLucky",
    fontSize: "15px",
    lineHeight: "140%",
    color: "#2B3B5C",
  },
  link: {
    color: "#4A55FE",
    fontWeight: "600",
    textDecoration: "underline",
    "&:hover": {
      color: "#4A55FE",
      textDecoration: "underline",
    },
  },
  "@media (max-width: 768px)": {
    root: {
      padding: "24px 24px 48px",
    },
    closeButton: {
      display: "inline",
      position: "absolute",
      right: "0",
      top: "0",
      fontFamily: "FaroWeb-RegularLucky",
      fontWeight: "bold",
      fontSize: "13px",
      lineHeight: "16px",
      color: "#8795B3",
    },
    title: {
      fontSize: "26px",
      lineHeight: "30px",
    },
    subtitle: {
      fontSize: "15px",
    },
  },
}

export function ZeroRiskPackageDialog(props) {
  const { classes, onClose, tooltipContent } = props
  return (
    <div className={classes.root}>
      <IconButton
        aria-label="close"
        className={classes.closeButton}
        onClick={onClose}
      >
        FERMER&nbsp;
        <i class="fal fa-times-circle" />
      </IconButton>
      <svg
        width="228"
        height="154"
        viewBox="0 0 228 154"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M187.33 142.57C200.189 142.57 210.612 132.147 210.612 119.288C210.612 106.43 200.189 96.0059 187.33 96.0059C174.472 96.0059 164.048 106.43 164.048 119.288C164.048 132.147 174.472 142.57 187.33 142.57Z"
          fill="#16DA88"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M183.751 124.465C175.91 118.245 187.691 111.459 194.388 109.31C196.319 114.513 193.128 127.934 184.539 125.068C186.351 121.261 188.479 117.981 190.882 115.153C191.315 114.663 190.449 114.136 190.055 114.626C187.533 117.679 185.405 120.922 183.751 124.465Z"
          fill="white"
        />
        <path
          d="M181.743 128.345L183.526 124.989L184.365 125.436L182.581 128.792L181.743 128.345Z"
          fill="white"
        />
        <path
          d="M33.4244 100.205C41.4282 100.205 47.9165 93.7165 47.9165 85.7128C47.9165 77.709 41.4282 71.2207 33.4244 71.2207C25.4207 71.2207 18.9324 77.709 18.9324 85.7128C18.9324 93.7165 25.4207 100.205 33.4244 100.205Z"
          fill="#4A55FE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M27.357 85.4066V84.5938H26.5347V83.1308H27.6695C27.8834 82.5132 28.1794 81.9442 28.5742 81.4078C28.9524 80.8876 29.413 80.4325 29.9393 80.0424C30.4492 79.6685 31.0248 79.3597 31.6498 79.1483C32.2748 78.937 32.9327 78.8232 33.6071 78.8232C34.3472 78.8232 35.0216 78.937 35.6301 79.1483C36.2387 79.3597 36.765 79.6523 37.2255 79.9936C37.6696 80.3512 38.0479 80.7576 38.3604 81.2127C38.6729 81.6841 38.8867 82.1555 39.0347 82.6432L36.4854 82.9683C36.3703 82.7245 36.2222 82.4969 36.0413 82.2693C35.8604 82.0418 35.6466 81.8467 35.3999 81.6679C35.1531 81.4891 34.89 81.359 34.5775 81.2453C34.265 81.1477 33.9196 81.099 33.5248 81.099C32.8669 81.099 32.2584 81.2778 31.6827 81.6516C31.1071 82.0255 30.663 82.5132 30.3505 83.1308H33.936V84.5938H29.9064V85.4066H33.936V86.8695H30.3505C30.663 87.4872 31.1071 87.9749 31.6827 88.3487C32.2584 88.7226 32.8669 88.9014 33.5248 88.9014C33.9525 88.9014 34.3472 88.8364 34.6926 88.7226C35.038 88.6088 35.3341 88.4625 35.6137 88.2675C35.8768 88.0724 36.1071 87.8448 36.3045 87.5847C36.5018 87.3409 36.6663 87.0646 36.8143 86.7882L39.3637 87.1133C39.2157 87.6173 38.9854 88.1212 38.6729 88.6088C38.344 89.0965 37.9328 89.5354 37.4558 89.9092C36.9624 90.2831 36.3867 90.5919 35.7453 90.8358C35.1038 91.0633 34.3801 91.1771 33.6071 91.1771C32.9327 91.1771 32.2748 91.0633 31.6498 90.852C31.0248 90.6407 30.4492 90.3319 29.9393 89.9417C29.413 89.5679 28.9524 89.1127 28.5742 88.5763C28.1794 88.0561 27.8834 87.4872 27.6695 86.8695H26.5347V85.4066H27.357Z"
          fill="white"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M176.877 87.4535C176.877 124.195 147.097 153.974 110.356 153.974C86.3583 153.974 65.3317 141.27 53.638 122.214C47.4161 112.101 43.835 100.197 43.835 87.4535C43.835 86.4132 43.855 85.3828 43.915 84.3625C45.5255 49.0514 74.6546 20.9326 110.356 20.9326C147.097 20.9326 176.877 50.712 176.877 87.4535Z"
          fill="#FCD660"
        />
        <path d="M177.352 46.5906L192.557 35.187" stroke="#FCD660" />
        <path d="M147.893 17.6064L150.941 9.32098" stroke="#FCD660" />
        <path d="M71.8688 15.7058L65.9493 0.287211" stroke="#FCD660" />
        <path d="M12.2623 56.828L36.6583 62.2706" stroke="#FCD660" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M167.494 23.5404L168.139 24.9055C168.198 25.029 168.31 25.1148 168.44 25.1341L169.882 25.3526C170.21 25.4024 170.341 25.8227 170.104 26.0642L169.059 27.127C168.966 27.2228 168.922 27.3611 168.945 27.4966L169.191 28.9963C169.247 29.3374 168.906 29.5973 168.613 29.436L167.323 28.7272C167.206 28.6645 167.067 28.6645 166.95 28.7272L165.66 29.436C165.368 29.5973 165.025 29.3374 165.082 28.9963L165.328 27.4966C165.35 27.3611 165.307 27.2228 165.213 27.127L164.169 26.0642C163.932 25.8227 164.063 25.4024 164.39 25.3526L165.833 25.1341C165.963 25.1148 166.075 25.029 166.133 24.9055L166.778 23.5404C166.925 23.2307 167.347 23.2307 167.494 23.5404Z"
          fill="#FCD660"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M50.6075 31.1429L51.2525 32.5081C51.3108 32.6316 51.4228 32.7173 51.5534 32.7367L52.9961 32.9551C53.3235 33.0049 53.4541 33.4252 53.2167 33.6667L52.1729 34.7295C52.0785 34.8254 52.0361 34.9636 52.0582 35.0991L52.3043 36.5989C52.3608 36.9399 52.0185 37.1998 51.7255 37.0385L50.4355 36.3297C50.319 36.267 50.1805 36.267 50.064 36.3297L48.7731 37.0385C48.481 37.1998 48.1386 36.9399 48.1942 36.5989L48.4404 35.0991C48.4633 34.9636 48.4201 34.8254 48.3266 34.7295L47.2818 33.6667C47.0454 33.4252 47.176 33.0049 47.5033 32.9551L48.946 32.7367C49.0757 32.7173 49.1887 32.6316 49.2469 32.5081L49.891 31.1429C50.0375 30.8332 50.4611 30.8332 50.6075 31.1429Z"
          fill="#FCD660"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M111.544 45.6401L79.4435 57.0495C71.3118 83.9502 80.3685 119.06 111.544 130.692C142.719 119.06 151.776 83.9502 143.644 57.0495L111.544 45.6401Z"
          fill="#4A55FE"
        />
        <path
          d="M111.781 53.2427V124.515C111.781 124.515 131.055 114.192 136.57 96.9877C142.085 79.7832 139.424 62.5786 139.424 62.5786L111.781 53.2427Z"
          fill="#2E3FD8"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M104.048 81.4459H103.71V97.9064H98.4771V74.1489H104.216L111.306 82.9731L118.396 74.1489H124.135V97.9064H118.902V81.4459H118.565L111.306 90.4398L104.048 81.4459Z"
          fill="#FCD660"
        />
        <path
          d="M223.204 115.962C225.697 115.962 227.718 113.941 227.718 111.448C227.718 108.956 225.697 106.935 223.204 106.935C220.711 106.935 218.69 108.956 218.69 111.448C218.69 113.941 220.711 115.962 223.204 115.962Z"
          fill="#16DA88"
        />
        <path
          d="M18.1272 124.254C22.0635 124.254 25.2545 121.064 25.2545 117.127C25.2545 113.191 22.0635 110 18.1272 110C14.191 110 11 113.191 11 117.127C11 121.064 14.191 124.254 18.1272 124.254Z"
          fill="#FCD660"
        />
        <path
          d="M3.26442 72.2427C4.57651 72.2427 5.64016 71.179 5.64016 69.867C5.64016 68.5549 4.57651 67.4912 3.26442 67.4912C1.95233 67.4912 0.888672 68.5549 0.888672 69.867C0.888672 71.179 1.95233 72.2427 3.26442 72.2427Z"
          fill="#4A55FE"
        />
      </svg>
      <h3 className={classes.title}>Pack Zéro Risque</h3>
      <h6 className={classes.subtitle}>Personne ne restera sur le carreau</h6>
      <p className={classes.textHeader}>Forfait transformé en bon d’achat</p>
      <p
        className={classes.text}
        dangerouslySetInnerHTML={{ __html: converter.makeHtml(tooltipContent) }}
      />
    </div>
  )
}

const StyledZeroRiskPackageDialog = withStyles(style)(ZeroRiskPackageDialog)

export default class ZeroRiskPackageTooltip extends Component {
  constructor(props) {
    super(props)
    this.state = {
      indexes: [],
    }
  }

  componentDidMount() {
    this.setState({ windowWidth: window.innerWidth })
    window.addEventListener("resize", this.update)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.update)
  }

  update = () => {
    this.setState({
      windowWidth: window.innerWidth,
    })
  }
  toggleTooltipDialog = () => {
    this.setState(prevState => ({
      isTooltipDialogOpen: !prevState.isTooltipDialogOpen,
    }))
  }

  render() {
    return [
      <InfoOutlinedIcon onClick={this.toggleTooltipDialog} />,
      <Dialog
        open={this.state.isTooltipDialogOpen}
        onClose={this.toggleTooltipDialog}
      >
        <StyledZeroRiskPackageDialog
          tooltipContent={this.props.TooltipContent || ""}
          onClose={this.toggleTooltipDialog}
        />
      </Dialog>,
    ]
  }
}
