import React, { useState, useEffect } from "react"
import TrustBox from "components/TrustBox"
import { makeStyles, Typography, Container, Link } from "@material-ui/core"

const repairmen = [
  {
    picture: "Lassana.png",
    name: "Lassana",
    experience: "3",
    astro_sign: "Cancer",
    desc: "Adore la cuisine !",
  },
  {
    picture: "Albert.png",
    name: "Albert",
    experience: "7",
    astro_sign: "Lion",
    desc: "Moulin à bons conseils",
  },
  {
    picture: "Adrien.png",
    name: "Adrien",
    experience: "4",
    astro_sign: "Balance",
    desc: "Fini de jeter, on répare !",
  },
  {
    picture: "Benjamin.png",
    name: "Benjamin",
    experience: "4",
    astro_sign: "Cancer",
    desc: "Répare depuis ses douze ans",
  },
]

export default function RepairmenBlock(props) {
  const [indexes, setIndexes] = useState([])
  const [windowWidth, setWindowWidth] = useState(0)
  const styles = useStyles()
  useEffect(() => {
    function update() {
      setWindowWidth(window.innerWidth)
    }
    setWindowWidth(window.innerWidth)
    getRandomIndexes()
    window.addEventListener("resize", update)
    return () => window.removeEventListener("resize", update)
  }, [])

  /**
   * This function returns an array of unique indexes
   * It starts by getting a random indexes within [0;repairmen.length[
   * Then it loops while a new unique number isn't picked (neededIndexes - 1 times)
   */
  function getRandomIndexes() {
    var maxIndex = repairmen.length
    var indexesTmp = []
    var neededIndexes = 3
    var tmpValue

    if (neededIndexes > maxIndex) {
      neededIndexes = maxIndex
    }
    for (neededIndexes; neededIndexes > 0; neededIndexes--) {
      while (
        indexesTmp.includes((tmpValue = Math.floor(Math.random() * maxIndex)))
      );
      indexesTmp.push(tmpValue)
    }
    setIndexes(indexesTmp)
  }
  function renderRepairmanItem(index) {
    var repairman = repairmen[index]
    var formattedPictureName =
      "/MASTER/Cobble%20Heroes/Reparateurs/" + repairman.picture
    var formattedExperience =
      repairman.experience +
      ` an${repairman.experience === "1" ? "" : "s"} d'expérience`
    var formattedAstro = "Signe astrologique: " + repairman.astro_sign

    return (
      <div className={styles.repairmanItem}>
        <img
          alt="portrait-repairman"
          className={styles.repairmanImage}
          src={`https://res.cloudinary.com/cobble/image/upload/${formattedPictureName}`}
        />
        <Typography className={styles.name}>{repairman.name}</Typography>
        <Typography className={styles.small}>{formattedExperience}</Typography>
        <Typography className={styles.small}>{formattedAstro}</Typography>
        <Typography className={styles.small}>{repairman.desc}</Typography>
      </div>
    )
  }

  /**
   * Responsive behavior:
   * on screens smaller than 768px, repairmen should be displayed inside a x-scrolling div
   *   as items should disapper on left and right sides of the screen, container padding has been removed.
   * on screens between 769px and 1092px, only one repairman item is displayed,
   */
  function renderRepairmenItems() {
    if (indexes.length > 0) {
      return (
        <div className={styles.repairmenItemsContainer}>
          {renderRepairmanItem(indexes[0])}
          {windowWidth < 768 || windowWidth > 1092
            ? renderRepairmanItem(indexes[1])
            : null}
          {windowWidth < 768 ? renderRepairmanItem(indexes[2]) : null}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <Container maxWidth="md" className={styles.blockContainer}>
      <div className={styles.contentContainer}>
        <Typography variant="h2">{props.title}</Typography>
        <Typography className={styles.text}>{props.text}</Typography>
        <Link
          className={styles.ctaButton}
          color="secondary"
          href={props.buttonLink}
        >
          {props.buttonText}&nbsp;
          <i>
            <svg
              width="15"
              height="11"
              viewBox="0 0 15 11"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.0769 5.80119C14.1137 5.72817 14.1321 5.63691 14.1321 5.52739C14.1321 5.41787 14.1137 5.30227 14.0769 5.18059C14.04 5.14408 14.0124 5.10757 13.9939 5.07107C13.9755 5.03456 13.9479 4.99197 13.911 4.9433L9.19417 0.270549C9.03449 0.124523 8.85024 0.0515137 8.64142 0.0515137C8.4326 0.0515137 8.24835 0.124523 8.08866 0.270549C7.94126 0.42874 7.86756 0.611267 7.86756 0.818134C7.86756 1.025 7.94126 1.20753 8.08866 1.36572L11.4789 4.72426H0.792283C0.546613 4.72426 0.35315 4.79423 0.21189 4.93417C0.0706292 5.07411 0 5.25968 0 5.49089C0 5.73426 0.0706292 5.92591 0.21189 6.06585C0.35315 6.20579 0.546613 6.27576 0.792283 6.27576H11.4789L8.08866 9.61605C7.94126 9.77424 7.86756 9.95677 7.86756 10.1636C7.86756 10.3705 7.94126 10.553 8.08866 10.7112C8.17465 10.7964 8.26984 10.8572 8.37425 10.8938C8.47866 10.9303 8.56772 10.9485 8.64142 10.9485C8.7274 10.9485 8.8226 10.9303 8.92701 10.8938C9.03142 10.8572 9.12047 10.7964 9.19417 10.7112L13.911 6.03848C13.9479 6.00197 13.9847 5.96546 14.0216 5.92896C14.0584 5.89245 14.0769 5.84986 14.0769 5.80119Z"
              />
            </svg>
          </i>
        </Link>
      </div>
      <div className={styles.repairmenContainer}>
        <div className={styles.repairmenSubcontainer}>
          {renderRepairmenItems()}
          <div className={styles.trustContainer}>
            <TrustBox isRepairmen />
            <Link
              href="https://fr.trustpilot.com/review/murfy.fr"
              target="_blank"
              rel="noopener noreferrer"
              color="textPrimary"
            >
              Lire les avis
            </Link>
          </div>
        </div>
      </div>
    </Container>
  )
}

const useStyles = makeStyles(theme => ({
  ctaButton: {
    fill: theme.palette.secondary.main,
    fontWeight: 500,
  },
  text: {
    marginBottom: theme.spacing(6),
    marginTop: theme.spacing(2),
  },
  blockContainer: {
    display: "flex",
    paddingLeft: 100,
    paddingRight: 100,
    [theme.breakpoints.down("xs")]: {
      flexDirection: " column",
      padding: "48px 0",
    },
  },
  contentContainer: {
    boxSizing: "border-box",
    padding: "6% 6% 6% 0",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      padding: "0 24px",
      width: "100%",
      order: 1,
      marginTop: theme.spacing(3),
    },
  },
  name: {
    textAlign: "center",
    fontSize: 20,
    lineHeight: "30px",
    fontWeight: "bold",
  },
  small: {
    textAlign: "center",
    fontSize: 15,
    lineHeight: "21px",
  },
  repairmenSubcontainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    float: "right",
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      alignItems: "start",
      overflowX: "scroll",
      width: "100%",
    },
  },

  repairmenContainer: {
    alignItems: "right",
    paddingLeft: 0,
    paddingRight: 0,
    width: "50%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      order: 0,
    },
  },
  repairmenCarouselSubTitleWrapper: {
    height: 33,
    width: 325,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#4A55FE",
    borderRadius: 18,
    fontSize: 15,
    color: "#FFFFFF",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  repairmenCarouselSubTitle: {
    marginBottom: 36,
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  repairmenCarouselSubtitleIcon: {
    // color: $yellow;
    height: 24,
    marginLeft: 8,
    width: 24,
  },
  repairmanItem: {
    alignItems: "center",
    backgroundColor: "white",
    borderRadius: 4,
    boxShadow:
      "0px 1px 10px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.08)",
    display: "flex",
    flexDirection: "column",
    marginLeft: 10,
    paddingBottom: 48,
    width: 230,
    borderBottom: `7px solid ${theme.palette.yellow}`,
  },
  trustContainer: {
    marginTop: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    "& a ": {},

    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  repairmanImage: {
    borderRadius: "50%",
    boxShadow:
      "0px 1px 10px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.08)",
    marginBottom: 24,
    marginTop: 36,
    height: 120,
    width: 120,
  },
  repairmenItemsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    "&:first-child": {
      marginLeft: 0,

      [theme.breakpoints.down("xs")]: {
        marginLeft: theme.spacing(2),
      },
    },

    [theme.breakpoints.down("xs")]: {
      "& :last-child": {
        marginRight: theme.spacing(2),
      },
    },
  },
}))
