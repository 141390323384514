import React, { Component } from "react"
import styles from "./conceptBlock.module.scss"
import TrustBox from 'components/TrustBox'
import ImgWithLazyload from "src/utils/components/ImageWithLazyload"

export default class ConceptBlock extends Component {

    getClassBackground =(index) => {
        if (index % 3 === 0)
            return styles.conceptItemBackgroundBlue
        return styles.conceptItemBackgroundYellow
        
    }
    getOderElement = (index) => {
        if(index % 2 ===0) return styles.itemOrderStyle2
        if(index===1) return styles.itemOrderStyle1
    }

    render() {
        const { items, title, displayBackground, isPreview } = this.props
        const blockContainerClass = displayBackground
            ? styles.blockContainerWithBackground
            : styles.blockContainer
        return (
            <div className={blockContainerClass}>
                <div className={styles.conceptBlockContent}>
                    <div className={styles.leftContent}>
                        <h2 className={styles.subtitle}>{title}
                        </h2>
                    <div className={styles.trustContainerDesktop}>
                    <TrustBox isRepairmen />
                        <a href="https://fr.trustpilot.com/review/murfy.fr" target="_blank" rel="noopener noreferrer">Lire les avis</a>
                    </div>
                    </div>
                    <div className={styles.itemsWrapper}>
                        {items.map(
                            (item, index) =>

                                <div className={`${styles.conceptItem} ${this.getClassBackground(index)} ${this.getOderElement(index)}`} key={index}>
                                    <div className={styles.iconContainer}>
                                        <ImgWithLazyload isPreview={isPreview} src={item.itemIcon} alt="" />
                                    </div>
                                    <div className={styles.textContainer}>
                                        <div className={styles.body1} key={index}>
                                            {item.itemTitle}
                                        </div>
                                        <div className={styles.small} key={index}>
                                            {item.itemText}
                                        </div>
                                    </div>
                                </div>
                        )}
                    </div>
                    <div className={styles.trustContainerMobile}>
                    <TrustBox isRepairmen />
                        <a href="https://fr.trustpilot.com/review/murfy.fr" target="_blank" rel="noopener noreferrer">Lire les avis</a>
                    </div>
                </div >
                
                
            </div >
        );
    }
}
