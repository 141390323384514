import React, { useEffect, useState } from "react"
import TrustBox from "components/TrustBox"
import { makeStyles, Typography, Container } from "@material-ui/core"

export default function ReviewBlock(props) {
  const styles = useStyles()
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" && window.innerWidth
  )

  useEffect(() => {
    function listenToResize() {
      typeof window !== "undefined" &&
        setWindowWidth({
          windowWidth: window.innerWidth,
        })
    }
    window.addEventListener("resize", listenToResize)
    return () => {
      window.removeEventListener("resize", listenToResize)
    }
  }, [])

  const { displayBackground, title, trustTag } = props
  const blockContainerClass = displayBackground
    ? styles.blockContainerWithBackground
    : styles.blockContainer

  return (
    windowWidth && (
      <Container maxWidth="md" className={blockContainerClass}>
        <div className={styles.blockSubcontainer}>
          <Typography
            variant="h2"
            className={styles.subtitle}
            color="secondary"
          >
            {title}
          </Typography>
        </div>
        <div className={styles.buttonContainer}>
          <TrustBox windowWidth={windowWidth} trustTag={trustTag} />
        </div>
      </Container>
    )
  )
}

const useStyles = makeStyles(theme => ({
  blockContainer: {
    padding: "0 24px",
    [theme.breakpoints.down("xs")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  blockContainerWithBackground: {
    backgroundColor: "#f4f3f8",
  },
  blockSubcontainer: {
    maxWidth: "1032px",
  },
  buttonContainer: {
    marginTop: theme.spacing(5),
  },
  subtitle: {
    [theme.breakpoints.down("xs")]: {
      textAlign: "center",
    },
  },
}))
