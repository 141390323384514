import React from "react"
import { makeStyles, Container, Typography } from "@material-ui/core"

import useColor from "src/hooks/useColor"
import Arrow from "./assets/arrow.svg"
import BigArrow from "./assets/logo.svg"
import ImageWithLazyload from "../../utils/components/ImageWithLazyload"

function Slide({ slide }) {
  const bgColor = useColor(slide.backgroundColor)
  const color = useColor(slide.textColor)
  const classes = useStyle({
    color,
    displayBigArrow: !(
      slide.backgroundImage && slide.backgroundImage.length > 0
    ),
    background: slide.backgroundImage ? "" : bgColor,
  })
  return (
    <div className={classes.slideContainer}>
      {slide.backgroundImage && (
        <div className={classes.picture}>
          <ImageWithLazyload src={slide.backgroundImage} />
        </div>
      )}
      <a href={slide.href} className={classes.slide}>
        <Typography variant="h1" component="h3" className={classes.title}>
          {slide.title}
        </Typography>
        <Typography className={classes.text}>{slide.text}</Typography>
        <Arrow className={classes.arrow} />
        <BigArrow className={classes.bigArrow} />
      </a>
    </div>
  )
}

export default function MachinaSlider({ machinaSlides }) {
  const classes = useStyle()
  return (
    <div className={classes.block}>
      <Container maxWidth="md" className={classes.container}>
        <div className={classes.inner}>
          {machinaSlides && machinaSlides.map(slide => <Slide slide={slide} />)}
        </div>
      </Container>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  block: {
    overflowX: "auto",
    "&::-webkit-scrollbar": {
      height: "8px",
    },
    "&::-webkit-scrollbar-track": {
      borderRadius: "10px",
      marginLeft: 500,
      marginRight: 500,
    },
    "&::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      backgroundColor: "transparent",
    },
    [theme.breakpoints.down("xs")]: {
      "&::-webkit-scrollbar": {
        display: "none",
      },
    },
    [theme.breakpoints.down("md")]: {
      "&::-webkit-scrollbar-track": {
        marginLeft: 200,
        marginRight: 200,
      },
    },
    "&:hover": {
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#f6f6f6",
        border: "1px solid #f6f6f6",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
  inner: {
    display: "flex",
    position: "absolute",
    left: theme.spacing(2),
    top: 0,
  },
  container: {
    position: "relative",
    height: 480,
    [theme.breakpoints.down("xs")]: {
      height: 300,
    },
  },
  arrow: {
    position: "absolute",
    bottom: 30,
    right: 30,
    fill: ({ color }) => color,
    [theme.breakpoints.down("xs")]: {
      bottom: 16,
      right: 16,
    },
  },
  bigArrow: {
    position: "absolute",
    top: 30,
    right: 0,
    fill: ({ color }) => color,
    display: ({ displayBigArrow }) => (displayBigArrow ? "block" : "none"),
    [theme.breakpoints.down("xs")]: {
      width: 120,
    },
  },
  title: {
    color: ({ color }) => color,
    marginBottom: theme.spacing(2),
    zIndex: 2,
  },
  text: {
    color: ({ color }) => color,
    zIndex: 2,
    maxWidth: "90%",
  },
  slide: {
    cursor: "pointer",
    width: 450,
    position: "relative",
    height: 450,
    background: ({ background }) => background,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    padding: 40,

    [theme.breakpoints.down("xs")]: {
      width: 280,
      height: 280,
      marginRight: theme.spacing(2),
      padding: theme.spacing(2),
    },
    "&:hover $arrow": {
      transform: "translateX(10px)",
      transition: "all 1000ms",
    },
  },
  slideContainer: { position: "relative", marginRight: 40 },
  picture: {
    position: "absolute",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    overflow: "hidden",
  },
}))
