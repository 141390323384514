import React, { Component } from 'react'
import PropTypes from "prop-types"
import styles from "./TrustBox.module.scss"
const TrustBox = ({ trustBoxRef, dataTemplateId, height, tpClass, trustTag }) => (
    <div
        ref={trustBoxRef} // We need a reference to this element to load the TrustBox in componentDidMount.
        className={tpClass} // Renamed this to className.
        data-locale="fr-FR"
        data-template-id={dataTemplateId}
        data-businessunit-id="5d9f0f6f95809300017a293e"
        data-style-height={height}
        data-style-width="100%"
        data-theme="light"
        data-tags={trustTag}
        data-stars="4,5">
        <a href="https://fr.trustpilot.com/review/murfy.fr" target="_blank" rel="noopener noreferrer">Trustpilot</a>
    </div>
);

class TrustBoxContainer extends Component {
    static propTypes = {
        isFooter: PropTypes.bool,
        isConcept: PropTypes.bool,
        windowWidth: PropTypes.any,
        trustTag: PropTypes.any
    }
    constructor(props) {
        super(props)
        this.trustBoxRef = React.createRef()
    }
    componentDidMount() {
        // If window.Trustpilot is available it means that we need to load the TrustBox from our ref.
        // If it's not, it means the script you pasted into <head /> isn't loaded just yet.
        // When it is, it will automatically load the TrustBox.
        if (window.Trustpilot) {
            window.Trustpilot.loadFromElement(this.trustBoxRef.current, true);
        }
    }

    render() {
        const { isFooter, isConcept, isRepairmen, windowWidth, trustTag } = this.props
        return <TrustBox
            trustBoxRef={this.trustBoxRef}
            height={isFooter || isConcept || isRepairmen ? "24px" : (windowWidth < 561 ? "300px" : "190px")}
            dataTemplateId={isFooter || isRepairmen ? "5406e65db0d04a09e042d5fc" : (isConcept ? "5419b732fbfb950b10de65e5" : "53aa8912dec7e10d38f59f36")}
            tpClass={isFooter ? styles.footerBox : (isConcept ? styles.conceptBox : (isRepairmen ? styles.repairmenBox : styles.blockBox))}
            trustTag={trustTag}
        />

    }
}
export default TrustBoxContainer