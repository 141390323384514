import React from "react"
import { makeStyles, Container, Typography } from "@material-ui/core"
import useColor from "src/hooks/useColor"
import Lottie from "react-lottie"

import animation from "./assets/circle.json"
import Tick1 from "./assets/tick1.svg"
import BigCross from "./assets/crossBig.svg"
import SmallCross from "./assets/crossSmall.svg"

const animationOptions = {
  loop: true,
  animationData: animation,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
}

export default function MurfyLaw({
  blocColor,
  firstText,
  secondText,
  number,
  textColor,
  backgroundColor,
  secondBackgroundColor,
}) {
  const textColorComputed = useColor(textColor)
  const backgroundColorComputed = useColor(blocColor)
  const topColor = useColor(backgroundColor)
  const bottomColor = useColor(secondBackgroundColor)
  const classes = useStyle({
    textColorComputed,
    backgroundColorComputed,
    topColor,
    bottomColor,
  })

  return (
    <div className={classes.bloc}>
      <Container maxWidth="md" className={classes.container}>
        <Typography className={classes.text}>{firstText}</Typography>
        <Typography className={classes.number}>
          {number}
          <Tick1 className={classes.tick1} />
          <BigCross className={classes.bigCross} />
          <SmallCross className={classes.smallCross} />
        </Typography>
        <Typography className={classes.text}>{secondText}</Typography>
        <div className={classes.animation__container}>
          <Lottie
            style={{ opacity: 0.3, width: "120%" }}
            options={{ ...animationOptions }}
          />
        </div>
      </Container>
    </div>
  )
}

const useStyle = makeStyles(theme => ({
  bloc: {
    background: ({ topColor, bottomColor }) =>
      `linear-gradient(${
        topColor === "inherit" ? "transparent" : topColor
      } 50%, ${bottomColor === "inherit" ? "transparent" : bottomColor} 50%)`,
  },
  container: {
    overflow: "hidden",
    position: "relative",
    padding: theme.spacing(2, 10),
    display: "flex",
    alignItems: "center",
    backgroundColor: ({ backgroundColorComputed }) => backgroundColorComputed,
    justifyContent: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      padding: theme.spacing(10, 3),
      width: "auto",
    },
  },
  bigCross: {
    position: "absolute",
    left: -20,
    bottom: 80,
  },
  smallCross: {
    position: "absolute",
    top: 90,
    right: -10,
  },
  animation__container: {
    position: "absolute",
    top: 0,
    left: "-30%",
    right: 0,
    bottom: 0,
  },
  tick1: {
    position: "absolute",
    top: "30%",
    left: "-10%",
    width: "120%",
  },
  number: {
    position: "relative",
    color: ({ textColorComputed }) => textColorComputed,
    fontWeight: 700,
    fontSize: 300,
    flex: 1,
    marginRight: 30,
    [theme.breakpoints.down("sm")]: {
      fontSize: 240,
    },
  },
  text: {
    color: ({ textColorComputed }) => textColorComputed,
    fontSize: 44,
    fontWeight: 700,
    flex: 4,
    [theme.breakpoints.down("sm")]: {
      fontSize: 30,
    },
  },
}))
