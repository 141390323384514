import React, { Component } from "react"

import styles from "./newHeaderBlock.module.scss"

export default class NewHeaderBlock extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hasScrolled: false,
      windowWidth: typeof window !== "undefined" && window.innerWidth,
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.listenToScroll)
    window.addEventListener("resize", this.listenToResize)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.listenToScroll)
    window.removeEventListener("resize", this.listenToResize)
  }

  listenToResize = () => {
    typeof window !== "undefined" &&
      this.setState({
        windowWidth: window.innerWidth,
      })
  }

  listenToScroll = () => {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    if (winScroll > 200) {
      this.setState({
        hasScrolled: true,
      })
    } else {
      this.setState({
        hasScrolled: false,
      })
    }
  }

  render() {
    const {
      newHeaderImage,
      newHeaderTitle,
      newHeaderSubTitle,
      newHeaderTextContent,
      newHeaderTextBottom,
    } = this.props
    return (
      <div className={styles.blockContainerHeader}>
        <div className={styles.left}>
          <div className={styles.subContainer}>
            <h2 className={styles.newHeaderTitleYellow}>{newHeaderTitle}</h2>
            <h2 className={styles.newHeaderTitleWhite}>{newHeaderSubTitle}</h2>
            <p className={styles.textContent}>{newHeaderTextContent}</p>
            <p className={styles.textBottom}>{newHeaderTextBottom}</p>
          </div>
        </div>

        <div className={styles.right}>
          <div
            className={styles.responsiveImage}
            style={{
              backgroundImage: `url(${newHeaderImage})`,
            }}
          ></div>
        </div>
      </div>
    )
  }
}
